<template>
    <div class="grid grid-cols-12">
        <div class="col-span-12 font-4xl opacity-85 text-gray-800 font-titillium-bold mt-30 ml-30 mb-20 lg:pl-20">
            {{$t('networkServicesPageTitle')}}
        </div>
        <!-- Success alert box -->
        <div class="col-span-6 flex justify-end items-center mr-30 relative">
            <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
            </div>
        </div>
        <!-- Networks services tables -->
        <div class="col-span-12 mx-30 pb-30 bg-white rounded-lg shadow">
            <div class="w-350 px-20 pt-20 mb-30">
                <label class="flex">{{$t('countyDropdownLabel')}}</label>
                <Multiselect
                    v-model="filters.name.value"
                    :options="counties"
                    class="rounded border border-gray-200"
                    :placeholder="$t('countyDropdownPlaceholder')"
                    :deselectLabel="$t('selectRemoveOptionText')"
                    :selectLabel="$t('selectOptionText')"
                    :selectedLabel="$t('selectOptionSelectedText')"
                >
                    <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                    <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                </Multiselect>
            </div>
            <hr />
            <v-table
                class="table"
                :pageSize="pageSize"
                :hideSortIcons="true"
                :data="allNetworkServices"
                :currentPage.sync="currentPage"
                @totalPagesChanged="totalPages = $event"
                @totalItemsChanged="totalElements = $event"
                :filters="filters"
            >
                <tbody
                    id="table_body"
                    class="tbody"
                    :class="[userTypeStaff ? 'grid grid-cols-1' : '']"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <tr
                        class="border-b"
                        :class="[userTypeStaff ? 'flex items-center pr-40' : '']"
                        v-for="row in displayData"
                        :key="row.id"
                    >
                        <td class="lg:w-2/4">
                            <p class="font-2xl2 text-gray-600 mb-10">{{row.name}}</p>
                            <p class="text-lg2 font-bold font-titillium-bold text-gray-800 mb-10">{{row.ngo.name}}</p>
                            <p class="font-titillium-normal text-gray-800">{{row.description}}</p>
                        </td>
                        <td class="pr-20 lg:w-3/12">
                            <div class="flex lg:justify-center flex-col lg:items-center">
                                <p class="font-4xl text-indigo-400">{{row.shared_amount}} {{row.measurement_unit}}</p>
                                <p class="text-gray-500">{{$t('networkServiceAvailability')}}</p>
                            </div>
                        </td>
                        <td
                            class="pr-20 lg:w-3/12"
                            v-if="userTypeManager"
                        >
                            <div class="flex lg:justify-center">
                                <Button
                                    v-if="!row.requested"
                                    @btnClick="openRequestModal(row), charactersLeft = 200, eraseErrors()"
                                    class="button w-170 bg-purple-500 text-white hover:bg-purple-600"
                                    role="button"
                                    :text="$t('networkServiceRequestButtonText')"
                                />
                                <p
                                    v-else
                                    class="text-purple-500 font-titillium-bold text-lg"
                                >{{ $t('requestSentText') }}</p>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="displayData.length === 0" class="col-span-12">
                        <td>
                            {{ $t('noServiceFoundText') }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <div v-if="totalElements" class="flex items-center justify-between mt-30">
                <div class="pl-10 p text-gray-800">{{page}}-{{pageSizeIncrement}} {{ $t('fromText') }} {{totalElements}} {{totalElements > 1 ? $t('fromServicesText') : $t('fromServicesTextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage"
                        :totalPages="totalPages"
                    />
                </div>
            </div>
        </div>
        <!-- Request service modal -->
        <Modal
            width='w-768'
            v-if="showRequestModal"
            @close="showRequestModal = false"
            @closeOnEscPressed="showRequestModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl opacity-85">{{$t('networkServicesRequestTitle')}}</h2>
            </div>
            <p
                class="my-30 p"
                slot="subtitle"
            >
                {{$t('networkServicesRequestSubtitle')}} <span class="font-bold">{{requestedService.ngo.name}}</span>
            </p>
            <div slot="body">
                <form
                    @submit.prevent="onRequest"
                    ref="requestServiceForm"
                >
                    <div class="grid grid-cols-3 gap-x-30">
                        <Input
                            :error="errors.amount"
                            class="col-span-1"
                            :class="[errors.message ? 'mb-4' : 'mb-20']"
                            :label="$t('networkServicesRequestQuantityLabel')"
                            :placeholder="$t('networkServicesRequestQuantityPlaceholder')"
                            type="number"
                            name="amount"
                        />
                        <div class="col-span-2 flex items-center">
                            <p class="text-5xl text-gray-500 mx-30">
                                /
                            </p>
                            <div>
                                <p class="font-4xl text-indigo-400">{{requestedService.shared_amount}} {{requestedService.measurement_unit}}</p>
                                <p class="text-gray-500">{{$t('networkServicesRequestAvailability')}}</p>
                            </div>
                        </div>
                        <div class="relative col-span-4 mb-30">
                            <Textarea
                                :error="errors.message"
                                :class="[errors.message ? 'mb-4' : '']"
                                v-on:input="remainingCharacters"
                                :maxlength="textareaMaxCharacters"
                                :label="$t('networkServicesRequestMessageLabel')"
                                name="message"
                                :placeholder="$t('networkServicesRequestMessagePlaceholder')"
                            />
                            <span class="absolute bottom-10 right-10 small-bold">{{charactersLeft}}/{{textareaMaxCharacters}}</span>
                        </div>
                    </div>
                    <div>
                        <Button
                            class="button w-257 bg-purple-500 text-white hover:bg-purple-600 mb-5 font-bold"
                            role="submit"
                            :text="$t('networkServiceSendRequestButtonText')"
                            :disabled="requestServiceLoading"
                            :spinning="requestServiceLoading"
                        />
                    </div>
                </form>
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/buttons/Button'
import Multiselect from 'vue-multiselect'
import Modal from '@/components/modal/Modal'
import Input from '@/components/form/Input'
import Textarea from '@/components/form/Textarea'
import SuccessBox from '@/components/success-message/SuccessBox'

export default {
    name: 'NetworkServices',
    components: {
        Button,
        Multiselect,
        Modal,
        Input,
        Textarea
    },
    data () {
        return {
            showRequestModal: false,
            requestedService: null,
            confirmationBox: false,
            currentPage: 1,
            totalPages: 0,
            counties: [],
            textareaMaxCharacters: 200,
            charactersLeft: 0,
            pageSize: 14,
            filters: {
                name: { value: '', keys: ['ngo.county.name'] }
            },
            pageSizeIncrement: null,
            currentPageSize: null,
            page: null,
            totalElements: null,

            requestServiceLoading: false
        }
    },
    computed: {
        ...mapGetters(['allNetworkServices', 'userTypeManager', 'userTypeStaff', 'token', 'requestServiceStatus', 'error', 'errors'])
    },
    methods: {
        ...mapActions(['getNetworkServices', 'makeServiceRequest', 'eraseErrors']),
        openRequestModal (service) {
            this.requestedService = service
            this.showRequestModal = true
        },
        /** Function for a service request. */
        onRequest () {
            this.requestServiceLoading = true

            /** Init success box */
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            /** Create the request object */
            const newRequest = {
                service: {
                    id: this.requestedService.id
                },
                message: this.$refs.requestServiceForm.message.value,
                amount: this.$refs.requestServiceForm.amount.value
            }

            /** Make the request */
            this.makeServiceRequest({ token: this.token, request: newRequest })
                .then(response => {
                    if (this.requestServiceStatus === true) {
                        this.requestServiceLoading = false
                        this.showRequestModal = false

                        /** Dynamically mount success box component */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('networkServicesRequestSuccessBoxSubtitle'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.requestServiceLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        /** Function to update the remaining characters number in the textarea */
        remainingCharacters (event) {
            this.charactersLeft = this.textareaMaxCharacters - event.length
        },

        /** Display info table in table footer method */
        getTableRecordsInfo () {
            this.currentPageSize = document.getElementById('table_body').childElementCount
            this.page = (this.pageSize * (this.currentPage - 1)) + 1

            if (this.totalElements < (this.pageSize * this.currentPage)) {
                this.pageSizeIncrement = (this.pageSize * (this.currentPage - 1)) + this.currentPageSize
            } else {
                this.pageSizeIncrement = (this.pageSize * this.currentPage)
            }
        }
    },
    updated () {
        this.getTableRecordsInfo()
    },
    mounted () {
        /** Get all the network services. */
        this.getNetworkServices({ token: this.token })
            .then(response => {
                for (const service in this.allNetworkServices) {
                    if (!this.counties.includes(this.allNetworkServices[service].ngo.county.name)) {
                        this.counties.push(this.allNetworkServices[service].ngo.county.name)
                    }
                }
                this.getTableRecordsInfo()
            })
    }
}
</script>
